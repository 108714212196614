





































import { Component, Vue } from 'vue-property-decorator'
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css'
import { TextBoxPlugin  } from '@syncfusion/ej2-vue-inputs';
import { DatePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { DropDownListPlugin } from "@syncfusion/ej2-vue-dropdowns";
import { NumericTextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
import { ButtonPlugin } from "@syncfusion/ej2-vue-buttons";
Vue.use(ButtonPlugin);
Vue.use(TextBoxPlugin);
Vue.use(DatePickerPlugin);
Vue.use(DropDownListPlugin);
Vue.use(NumericTextBoxPlugin)
@Component({
    components: {
    VueTelInput,
  }
})
export default class AddUser extends Vue {
  countryCode=null;
  number=null;
  telOption = {type:'text',required:true,placeholder:"Enter Mobile Number",name:"telMobile",readonly:false};
   data:any={};
   Roles = ["Partner","Sales","School","TeacherManager","RoleManager","ContentManager"]
 validateMobile(data:any){
    if(data.valid===true){
       this.data.countryCode = data.countryCode;
       this.data.Mobile = "+"+data.countryCallingCode+"-"+data.nationalNumber;
    }else{
         this.data.countryCode = null;
       this.data.Mobile = null;
    }
 }
}
